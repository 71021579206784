<!-- =========================================================================================
    File Name: NotAuthorized.vue
    Description: Not Authorized Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img
        src="@/Core/assets/images/pages/not-authorized.png"
        alt="graphic-not-authorized"
        class="mx-auto mb-4"
      />
      <h1 class="sm:mx-0 mx-4 sm:mb-12 mb-8 text-2xl d-theme-heading-color">
        The page you are trying to access is not available.
      </h1>
      <!-- <p class="sm:mx-0 mx-4 sm:mb-12 mb-8 d-theme-text-inverse">
        paraphonic unassessable foramination Caulopteris worral Spirophyton
        encrimson esparcet aggerate chondrule restate whistler shallopy
        biosystematy area bertram plotting unstarting quarterstaff.
      </p> -->
      <!-- <vs-button size="large" to="/" @click="clearData">Back to Home</vs-button> -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clearData() {
      localStorage.clear();
      this.$session.destroy();
    }
  },
  mounted() {
    this.clearData();
  }
};
</script>
